import React from 'react'
import './KubernetesContent.scss'
import CallToActionBanner from '../../CallToActionBanner/CallToActionBanner'
import { Link } from '@reach/router'
import SolutionsFeed from '../../Solutions/SolutionsFeed/SolutionsFeed'

export class KubernetesContent extends React.Component {
  render() {
    return (
      <div className="kubernetes-content">
        <div className="split light subheader-block">
          <div className="text-wrap">
            Rackner's Certified Kubernetes Administrators stay on the cutting
            edge of the Kubernetes Ecosystem, and work with large clusters every
            day in production.
          </div>
        </div>
        <section className="padding-large">
          <div className="row">
            <div className="col-md-6">
              <h3>End to End Support</h3>
              <div className="service-row">
                <div className="text-wrap">
                  <div className="title">Build</div>
                  <p>
                    Run clusters on a public cloud provider or On Premise. We
                    use best-in-breed cluster bootstrapping and management
                    tooling to ensure that clusters are created in a conformant,
                    performant, and flexible way, with the ability to autoscale
                    and self-heal.
                  </p>
                </div>
              </div>
              <div className="service-row">
                <div className="text-wrap">
                  <div className="title">Secure</div>
                  <p>
                    By following security best practices, and harnessing
                    industry leading tools for both Kubernetes itself and the
                    workloads running on it, we ensure that your Kubernetes
                    deployment is as secure as possible.
                  </p>
                </div>
              </div>
              <div className="service-row">
                <div className="text-wrap">
                  <div className="title">Operate</div>
                  <p>
                    Make your developers' lives easier by implementing
                    monitoring, CI/CD, and logging. Help ensure the reliability
                    of your cluster - we'll hold the pager.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <img src="/kubernetes-kcsp-color.png" />
            </div>
          </div>
        </section>
        <div className="split pattern">
          <h1>Run Anywhere</h1>
          <div className="row migration-paths">
            <div className="col-sm-4">
              <div className="path">
                <div className="title">Amazon Web Services</div>
                <div className="icon-wrap" />
                <div className="description">
                  We can help you deploy on AWS Elastic Kubernetes Service or by
                  creating a best-practices cluster from scratch on EC2
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="path">
                <div className="title">Google Cloud Platform</div>
                <div className="icon-wrap" />
                <div className="description">
                  Choose between Google Kubernetes Engine and Google Compute
                  Engine depending on your needs.
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="path">
                <div className="title">On-Prem/Edge/Custom</div>
                <div className="icon-wrap" />
                <div className="description">
                  We can help with Kubernetes on other cloud providers,
                  configure managed services, or build on on-premise hardware.
                </div>
              </div>
            </div>
          </div>
          <div className="button-wrap">
            <Link to="/quote">
              <button className="btn-raised-light-blue-inverse big">
                Get a quote
              </button>
            </Link>
          </div>
        </div>
        <div className="split light">
          <div className="kube-solutions-wrapper">
            <h1>Recommended for your organization</h1>
            <SolutionsFeed
              hideHeader={true}
              solutions={this.props?.solutions}
            />
          </div>
        </div>
        <CallToActionBanner route={'insights'} linkText={'See Case Studies'} />
      </div>
    )
  }
}

export default KubernetesContent
