import React from 'react'
import './KovalentHero.scss' 

export class KovalentHero extends React.Component {
  render() {
    return (
      <div
        className="cloudsecops-page-hero"
        style={{ backgroundImage: 'url(/cloudsecopsbg.jpg)' }}
      >
        <div className="overlay" />
        <div className="inner-wrap">
          <div className="text">
            <h1>Enclave AI: Deploying AI from edge to airgap</h1>
            <h2>We're building a platform to make ML and analytics easy for embedded, edge and airgap use cases across industries like defense, manufacturing, and more.</h2>
          </div>
        </div>
      </div>
    )
  }
}

export default KovalentHero
