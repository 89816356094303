import React from 'react'
import './KubernetesHero.scss' 

export class KubernetesHero extends React.Component {
  render() {
    return (
      <div
        className="cloudsecops-page-hero"
        style={{ backgroundImage: 'url(/cloudsecopsbg.jpg)' }}
      >
        <div className="overlay" />
        <div className="inner-wrap">
          <div className="text">
            <h1>Kubernetes Certified Service Provider</h1>
            <h2>Build, Secure, and Operate with our proven Kubernetes expertise</h2>
          </div>
        </div>
      </div>
    )
  }
}

export default KubernetesHero
