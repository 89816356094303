import React from 'react'
import './KovalentContent.scss'
import CallToActionBanner from '../../CallToActionBanner/CallToActionBanner'
import { Link } from '@reach/router'

export class KovalentContent extends React.Component {
  render() {
    return (
      <div className="kubernetes-content">
        <div className="light">
          <section className="padding-large ">
            <div className="row">
              <div className="col-md-12">
                <h3>Embedded AI and Analytics</h3>
                <div className="service-row">
                  <div className="text-wrap">
                    <p>
                      Writing secure software for embedded devices is hard. Much
                      of the embedded world runs on hardware that cannot support
                      Containers or Kubernetes, reducing much of the delivery
                      gains that are available to the rest of the software
                      world. We are looking to solve that problem, especially
                      for Defense and large-scale industry use cases, through
                      Enclave AI.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="">
          <section className="padding-large ">
            <div className="row">
              <div className="col-md-12">
                <h3>Want to learn more?</h3>
                <div className="service-row">
                  <div className="text-wrap">
                    <p>
                      If you're an organization looking to better harness data
                      and metrics across a fleet of devices, new or old - we'd
                      love to talk. Please reach out to us at{' '}
                      <b>contact@rackner.com</b> or through the form on this
                      website
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <CallToActionBanner route={'contact'} linkText={'Get in touch'} />
      </div>
    )
  }
}

export default KovalentContent
